import axios from 'axios';
import User from '@api/client/User';
import Captcha from '@api/client/Captcha';
import Drive from '@api/client/Drive';
import Download from '@api/client/Download';
import Server from '@api/client/Server';

class Client {
    /**
     * @type {boolean}
     */
    debug = false;
    /**
     * @type {string}
     */
    apiUrl = '/api';
    /**
     * @type Request
     */
    request;
    /**
     * @type {object}
     */
    headers = {
        'Content-Type': 'application/json',
    };
    errorHooks = [];

    constructor() {
        const self = this;
        if (process.env.NODE_ENV === 'development') {
            this.apiUrl = 'http://localhost:8081/api';
        }
        // this.request = axios;
        for (let k in this.headers) {
            axios.defaults.headers[k] = this.headers[k];
        }

        this.request = axios.create({
            withCredentials: true,
        });
        // Add a response interceptor
        this.request.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            for (let i = 0; i < self.errorHooks.length; i++) {
                self.errorHooks[i](error.response);
            }
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }

    errorHook(fn) {
        this.errorHooks.push(fn);
    }

    getUser() {
        return new User(this);
    }
    getCaptcha() {
        return new Captcha(this);
    }
    getDrive() {
        return new Drive(this);
    }
    getDownload() {
        return new Download(this);
    }
    getServer() {
        return new Server(this);
    }

}

let client = new Client();
export default client;

export const UserApi = client.getUser();
export const CaptchaApi = client.getCaptcha();
export const DriveApi = client.getDrive();
export const DownloadApi = client.getDownload();
export const ServerApi = client.getServer();
