import BaseResponse from "./BaseResponse";
import _ from "lodash";

class BaseClient {

    /**
     * @type Client
     */
    client;

    constructor(client) {
        this.client = client;
    }

    getFullUrl(uri) {
        return this.client.apiUrl + uri;
    }

    async requestGet(uri, params = []) {
        const response = await this.client.request.get(this.getFullUrl(uri), {
            params: params,
            data: {
                name: "john"
            },
            headers: this.client.headers,
        });
        return new BaseResponse(response);
    }

    async requestUrlGet(url, params = []) {
        const response = await this.client.request.get(url, {
            params: params,
            headers: this.client.headers,
        });
        return new BaseResponse(response);
    }

    async requestPost(uri, params = []) {
        const response = await this.client.request.post(this.getFullUrl(uri), params );
        return new BaseResponse(response);
    }

    async requestPatch(uri, params = []) {
        const response = await this.client.request.patch(this.getFullUrl(uri), params);
        return new BaseResponse(response);
    }

    async requestUrlPost(url, params = []) {
        const response = await this.client.request.post(url, params);
        return new BaseResponse(response);
    }

    async requestPostFormData(uri, params = []) {
        const formData = new FormData();
        for (let k in params) {
            if (_.isArray(params[k])) {
                for (let j in params[k]) {
                    formData.append(`${k}[]`, params[k][j]);
                }
            } else {
                formData.append(k, params[k]);
            }
        }
        const response = await this.client.request.post(this.getFullUrl(uri), formData, {
            headers: {
                ...this.client.headers,
                ...{
                    'Content-Type': 'multipart/form-data',
                },
            },
        });

        return new BaseResponse(response);
    }

    async requestPut(uri, params = []) {
        const response = await this.client.request.put(this.getFullUrl(uri), params);
        return new BaseResponse(response);
    }

    async requestDelete(uri, params = []) {
        const response = await this.client.request.delete(this.getFullUrl(uri), {
            data: params,
        });
        return new BaseResponse(response);
    }

}

export default BaseClient;
