import BaseClient from "@api/BaseClient";

class Server extends BaseClient {

  /**
   * List
   * @param {object} options
   * {
   * 	"page": 1,
   * 	"pageSize": 2,
   * 	"sort": "name",
   * 	"sortOrder": "desc",
   * 	"filter": {
   * 		"keyword": "",
   * 		"keywordType": "contains"
   * 	}
   * }
   * @returns {Promise<*>}
   */
  async list(options = {}) {
    const response = await this.requestPost(`/server/list`, options);
    return response.getData();
  }

  /**
   * Get
   * @param {integer} id
   * @returns {Promise<*>}
   */
  async get(id) {
    const response = await this.requestGet(`/servers/${id}`);
    return response.getData();
  }

  /**
   * Create
   * @param {object} options
   * {
   *   "type": "1",
   * 	 "label": "test",
   *   "ssh_ip": "127.0.0.1",
   *   "ssh_port": 22,
   *   "ssh_username": "root",
   *   "note": "test note",
   *   "status": "1"
   * }
   * @returns {Promise<*>}
   */
  async create(options = {}) {
    const response = await this.requestPost(`/servers`, options);
    return response.getData();
  }

  /**
   * Update
   * @param {integer} id
   * @param {object} options
   * {
   *   "type": "1",
   * 	 "label": "test",
   *   "ssh_ip": "127.0.0.1",
   *   "ssh_port": 22,
   *   "ssh_username": "root",
   *   "note": "test note",
   *   "status": "1"
   * }
   * @returns {Promise<*>}
   */
  async update(id, options = {}) {
    const response = await this.requestPut(`/servers/${id}`, options);
    return response.getData();
  }

  /**
   * Delete
   * @param {integer} id
   * @returns {Promise<*>}
   */
  async delete(id) {
    const response = await this.requestDelete(`/servers/${id}`);
    return response.getData();
  }

}

export default Server;
