import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import theme from './theme';
import LazySpinner  from '@components/Loading/LazySpinner';
import Client from '@api/Client';

const Server = React.lazy(() => import('@pages/Server'));
const Login = React.lazy(() => import('@pages/Login'));

function App() {
  const toast = useToast()

  useEffect(() => {
    Client.errorHook((error) => {
      const {message} = error.data;
      if (error.status === 401) {
        window.location = '/login?redirect=' + encodeURIComponent(window.location.href);
      }
      toast({
        position: 'top',
        title: '错误提醒',
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<LazySpinner/>}>
        <Routes>
          <Route path='/servers' element={<Server />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </Suspense>
    </ChakraProvider>
  );
}



export default App;
